























































































































































import { catApi, catInput, catVue } from "@/util/logging";
import { clearTimeout, setTimeout } from "timers";

import { BuchVerkauf } from "@/models/BuchVerkauf";
import { PermissionType } from "@/models/UserDataModel";
import Vue from "vue";
import { api } from "@/util/api";
import store from "@/store";

import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";

export default Vue.extend({
  name: "Buchscsanner",
  components: {
    //  BuchAnzeige: () =>
    //    import(
    //      /* webpackChunkName: "BuchAnzeige" */ "@/components/regalkontrolle/BuchAnzeige.vue"
    //    ),
    //  PresseAnzeige: () =>
    //    import(
    //      /* webpackChunkName: "PresseAnzeige" */ "@/components/regalkontrolle/PresseAnzeige.vue"
    //    )
  },
  mounted() {
    // get key if exists
    //
    const storage = window.localStorage;
    if (storage) {
      this.reqKey = storage.getItem("buchscanner-key");
    }
    catVue.info(`Mounted BuchScanner, key is ${this.reqKey}`);

    if (!storage || this.reqKey == null) {
      store.commit("setLastError", "Kein Schlüssel gesetzt.");
    }

    if (this.reqKey !== null)
      if ("input" in this.$refs)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.input as any).focus();
      else if (this.auth.theFiliale !== null) {
        catVue.info(`theFiliale := ${JSON.stringify(this.auth.theFiliale)}`);
        let row = this.auth.theFiliale.url.substring(
          this.auth.theFiliale.url.lastIndexOf("/") + 1
        );
        row = row + "-" + this.auth.theFiliale?.f_vn;

        this.bsKey = row;
      }

    this.lastKey = Date.now();

    if (store.state.isAppLoading)
      this.$store.commit("changeAppLoadingState", false, { root: true });
  },
  data: () => ({
    valid: true,
    validKey: true,
    produkt: "",
    action: "V",
    menge: 1,
    bsKey: "",
    //
    // Validation Rules
    produktRules: [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (v: any) => !!v || "Irgendein Barcode muss sein",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (v: string) => v.length >= 2 || "Zwei Zeichen solltens schon sein",
    ],
    buchVer: undefined as BuchVerkauf | undefined,
    bvListe: [] as BuchVerkauf[],
    //
    // For the time-based autofocus
    lastKey: -1,
    timeOut: undefined as ReturnType<typeof setTimeout> | undefined,
    reqKey: null as string | null,
    //
    auth: getModule(AuthModule, store),
  }),
  computed: {
    special(): string {
      return BuchVerkauf.decode(this.action);
    },
  },
  props: {
    /* intentionally empty */
  },
  methods: {
    //
    isAdmin(): boolean {
      return (
        this.$store.state.auth.loggedIn &&
        this.$store.state.auth.user.Permissions & PermissionType.PERM_SUPERUSER
      );
    },

    //
    // submit sendet den aktuellen Eintrag 8wenn gültig) an das Backend.
    //
    async submit() {
      catInput.info("in submit ...");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(this.$refs.form as any).validate()) return;

      if (null === this.reqKey) {
        catInput.error("Hierher sollten wir nie kommen. Kein APi Key!", null);
        return;
      }

      if (null === this.produkt) {
        catInput.error("Häh??", null);
        return;
      }

      if (this.handleCommand()) {
        this.produkt = "";
        return;
      }

      const bv = BuchVerkauf.New(
        this.reqKey,
        this.action,
        this.menge,
        this.produkt
      );

      catInput.info(`vor : ${JSON.stringify(bv)}`);
      this.$store.commit("changeAppLoadingState", true, { root: true });
      const ok = await bv.post();
      this.$store.commit("changeAppLoadingState", false, { root: true });
      catInput.info(`nach: ${JSON.stringify(bv)}`);
      if (!(ok === true))
        store.commit("setLastError", "Fehler beim Abspeichern.");
      else {
        store.commit("setLastError", "");
      }
      this.bvListe.splice(0, 0, bv);
      this.clear();
    },

    //
    //
    // Hier die Original-Scanner-Codes
    //
    // Reservierung            ::
    // ÖÖ (R(eservierung))     :: *R*eservierung
    // ÖÖ )ß1=  Menge          :: (-1)
    // ÖÖ )X=  Yurueck         :: (X) Zurück
    //

    handleCommand(): boolean {
      //
      // Scanner Commandos beginnen alle (!) mit ":: "
      this.produkt = this.produkt.trim();
      if (!this.produkt.startsWith(":: ")) {
        return false;
      }

      let inner = "";
      let code = "";
      let indicator = this.produkt.substring(3, 4);
      switch (indicator) {
        case "*":
          if (this.produkt.charAt(5) === "*")
            code = this.produkt.substring(4, 5);
          break;

        case "(":
          {
            const pos = this.produkt.indexOf(")", 4);
            if (pos > 3) inner = this.produkt.substring(4, pos);
          }
          break;
        default:
          catInput.warn("unbekanntes Kommando");
          this.clear();
          return true;
      }

      //
      // :: *W* In die Reservierung
      // :: *Z* Zurück ins Regal
      if (code !== "") {
        switch (code) {
          case "R":
          case "V":
          case "W":
          case "Z":
            this.action = code;
            break;

          case "X":
            this.clear();
            break;

          default:
            catInput.warn("unbekannter Code: " + code);
        }
        return true;
      }

      //
      // Mengen:
      // :: (-1) Minus 1
      // :: (5) 5
      if (inner !== "") {
        if (inner === "X") {
          this.clear();
          return true;
        }
        let nmbr = parseInt(inner);
        if (isNaN(nmbr)) {
          catInput.warn("keine Zahl!");
          return false;
        }
        this.menge = nmbr;
        return true;
      }

      catInput.warn("Irgendwie ist nichts los gewesen??");
      return false;
    },

    //
    // clear all the inputs
    //
    clear() {
      this.action = "V";
      this.menge = 1;
      this.produkt = "";
    },

    //
    // setKey setzt den Buchscanner Schlüssel und speichert den erhaltenen APi Key in einer lokalen Variable ab
    //
    async setKey() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(this.$refs.form as any).validate()) return;

      catVue.info(`Neuer Schlüssel: ${this.bsKey}`);
      //
      const storage = window.localStorage;
      if (storage) {
        storage.setItem("buchscanner-key", this.bsKey);
        this.reqKey = storage.getItem("buchscanner-key");

        const path = `v1/filialen/buchscannerKey`;
        this.$store.commit("changeAppLoadingState", true, { root: true });
        try {
          const response = await api.post(path, { key: this.bsKey });
          catApi.info(
            `response: /${path} (${JSON.stringify({
              key: this.bsKey,
            })}) -> ${JSON.stringify(response)}`
          );
          if ("data" in response) {
            const r = response.data as Record<string, string>;
            if ("key" in r) {
              storage.setItem("buchscanner-key", r.key);
              this.reqKey = storage.getItem("buchscanner-key");
            }
          }
        } catch (err) {
          catApi.info("API Error/ Exception: " + err);

          storage.removeItem("buchscanner-key");
          this.reqKey = null;
        }
        this.$store.commit("changeAppLoadingState", false, { root: true });
      }

      if (!storage || this.reqKey == null) {
        store.commit("setLastError", "Fehler beim Setzen des Schlüssels.");
      }

      this.clear();
    },

    //
    // Re-fokus-Methode
    //
    keyDown(): void {
      if (undefined !== this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        catVue.info("focus ...");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const input = (this.$refs["input"] as any).$refs.input;
        input.select();
        input.focus();
      }, 7499);
    },
  },
});
