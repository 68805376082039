import { BaseType } from "./util/BaseType";
import { Expose } from "class-transformer";
import { api } from "@/util/api";
import { catApi } from "@/util/logging";
import { ulid } from "ulid";

const fmt = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

export class BuchVerkauf extends BaseType {
  // For unique identification and matchind
  @Expose() uid = ulid();
  // Input Values
  @Expose() key = undefined as string | undefined;
  @Expose() action = "";
  @Expose() barcode = undefined as string | undefined;
  @Expose() menge = 1;
  // Return valies
  @Expose() titel = undefined as string | undefined;
  @Expose() preis = 0.0;

  public fmtPreis(): string {
    return fmt.format(this.preis);
  }

  public decoded(): string {
    return BuchVerkauf.decode(this.action);
  }

  public isDone(): boolean {
    return !(this.titel === undefined);
  }

  public isSendable(): boolean {
    return this.key !== undefined && this.barcode !== undefined;
  }

  // === === === === === === === === === === === === === ===

  static New(
    key: string,
    action: string,
    menge: number,
    barcode: string
  ): BuchVerkauf {
    const neu = new BuchVerkauf();
    neu.key = key;
    neu.barcode = barcode;
    neu.action = action;
    neu.menge = menge;

    return neu;
  }

  static decode(a: string): string {
    switch (a) {
      case "V":
        return "☺←|☰|";
      case "R":
        return "☺↜↜R";
      case "W":
        return "|☰|→R";
      case "Z":
        return "|☰|←R";
      default:
        return "??";
    }
  }

  // === === === === === === === === === === === === === ===

  public post = async (): Promise<boolean> => {
    //
    catApi.info("querying: " + this.uid);

    if (this.isDone()) {
      catApi.warn("… ist schon erledigt?");
      return true;
    }

    if (this.key === undefined) {
      catApi.error("How can this be — no Key", null);
      return false;
    }

    if (this.barcode === undefined) {
      catApi.warn("no Barcode, no send");
      return false;
    }

    try {
      const data = {
        uid: this.uid,
        key: this.key,
        action: this.action,
        barcode: this.barcode,
        menge: this.menge,
      };
      const path = `v1/buecher/verkauf`;
      const response = await api.post<BuchVerkauf>(path, data);
      catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

      const ret = BaseType.convert(BuchVerkauf, response.data);

      if (ret === null) {
        catApi.error("Should have gotten a struct back, got NULL", null);
        throw new Error("BuchVerkauf null");
      }

      if (ret.uid !== this.uid) {
        catApi.error("Wrong Return Object", null);
        throw new Error("BuchVerkauf ULID mismatch");
      }

      this.titel = ret.titel;
      this.preis = ret.preis;

      return this.isDone();
      //
    } catch (e) {
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, err);
      this.titel = undefined;
      return false;
    }
  };
}
